<template>
  <div class="container mt-4">
   <div class="row">
    <div class="row ">
      <img src="./iheader.png" alt="header" width="100%" />
    </div>
  

    <table class="table table-sm m-0 headertable">
       <tr class="border-bottom ">
      <td class="text-center" colspan="4">
         فاتورة ضريبية 
      </td>
    </tr>
      <tr>
        <td class="text-right">  شركة ابـناء عـبدالرحمن عـلي الـبصـيلـى</td>
        <td class="text-right"> :اسم المقاول</td>
        <td class="text-right"></td>
        <td class="text-right">{{ invoice.invoice_id }}:رقم الفاتورة</td>
      </tr>
      <tr>
        <td class="text-right">1010152299</td>
        <td class="text-right">: رقم السجل التجارى</td>
        <td class="text-right">{{ invoice.ref_invoice }}</td>
        <td class="text-right">:الرقم المرجعي الداخلي</td>
        
      </tr>
      <tr>
        <td class="text-right"><u>300047805100003</u></td>
        <td class="text-right">:الرقم الضريبى</td>
        <td class="text-right">
          الموافق: {{hijri(invoice.created_at) }}
        </td>
        <td class="text-right">{{ invoice.created_at | moment("YYYY/MM/DD") }} :تاريخ الفاتورة</td>
      </tr>
      <tr>
        <td class="text-right"></td>
        <td class="text-right"></td>
        <td class="text-right">
           الموافق: {{hijri(invoice.invoice_start) }}
        </td>
        <td class="text-right"> {{ invoice.invoice_start | moment("YYYY/MM/DD") }} :تـاريخ التسليم</td>
      </tr>
      <tr>
        <td class="text-right"> مـستحـق مـالـي </td>
        <td class="text-right">:نــوع الفاتورة</td>
        
        <td class="text-right">الـقـيادة الـعـامـة لـطـيـران األمــن - بالمنطقة الشرقية</td>
        <td class="text-right"> :العــميـل</td>
      </tr>
      <tr>
        <td>
          <span class="float-left">
            <vue-qrcode
              :value="invoice.qrcode"
              :options="{ width: 150 }"
            ></vue-qrcode>
          </span>
        </td>
        <td class="text-right"></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
      </tr>
    </table>
   </div>
    <div class="row">
    <table class="table table-sm m-0">
      <tr>
        <td  colspan="5"  class="text-right">  {{invoice.description }}    </td>
        <td colspan="1"> الـبـيـان</td>
      </tr>
         <tr>
              <th class="text-center">اجمالي تكلفة الشراء والترحيل</th>
              <th class="text-center">تكلفة الترحيل للتر الواحد</th>
              <th class="text-center">سعر شراء اللتر</th>
              <th class="text-center">الكمية باللتر</th>
              <th class="text-center">الـوصــف</th>
              <th class="text-center">م</th>
            </tr>
       <tr v-for="(value, index) in items" :key="value.item">
              <td class="text-right"> 
                {{comma(value.amount)}}
              </td>
              <td class="text-center"> 
                <div  v-if="index==1">
                  {{comma_four(value.absent) }}              
                </div>              
              </td>
              <td class="text-center"> 
                <div  v-if="index==0">
                  {{comma_four(value.absent) }}              
                </div>
              </td>
              <td class="text-center"> {{comma(value.quantity) }}
              </td> 
              <td class="text-right">{{ value.item_arabic }}
              </td>
              <td class="text-center">{{ index + 1 }}
              </td>
            </tr>
            <tr v-if="items.length > 0" >
              <td colspan="4" class="text-right"> {{ round(items[0].amount, 0.15)}}
              </td>
              <td  colspan="2" class="text-right"> ضريبة القيمة المضافة 15 %من سعر شراء الوقود
              </td>
            </tr>
          <tr v-if="items.length > 0" >
              <td colspan="4" class="text-right"> {{round(items[1].quantity, 0.0675 )}}
              </td>
              <td  colspan="2" class="text-right">
                ضريبةالقيمةالمضافة 15 % (45.)من خدمات ترحيل للتر الوقود-0.0675
              </td>
            </tr>
        <tr>
        <td colspan="4" class="text-center">{{ comma(invoice_total_amount) }}</td>
        <td colspan="2" class="text-right"> السعر اإلجمالي للوقود ورسوم الخدمة بدون ضريبة القيمة المضافة   </td>
      </tr>

      <tr>
        <td colspan="4"  class="text-center">{{ comma(invoice_vat_amount) }}</td>
        <td colspan="2" class="text-right">اجــمــالي ضريـبــة القيـمة المضافة %15</td>
      </tr>

      <tr>
        <td  colspan="4" class="text-center">{{ comma(invoice_gross_amount) }}</td>
        <td colspan="2" class="text-right">الاجــمــالي بعد الضــريــبــة</td>
      </tr>

    
      <tr>
        <td colspan="6" class="text-right">
           القيمة بالحروف :{{ invoice.amount_words_ar }}
        </td>
      </tr>
        <tr>
         <td colspan="6" class="text-left">
          <div style="margin-left: 1em; height:100px;  width:200px; border-bottom:solid 1px black;">
          </div>
          <div style="width:200px;"> 
            <h2 class="text-right">  احمــد العـنزي </h2>
            <h2 class="text-right">  مدير المشروع </h2>
        </div>
        </td>
      </tr>
    </table>
    </div>

    <div class="row">
      <img src="./ifooter.png" alt="header" width="100%">
    </div>
  </div>
</template>

<script>
const VueQrcode = require("@chenfengyuan/vue-qrcode");
import HRMService from "../../../../services/hrm-service";
export default {
  components: {
    VueQrcode: VueQrcode,
  },
  data: () => {
    return {
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
      hc_address : ''
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
      comma(data){    
          return Number(parseFloat(data).toFixed(3)).toLocaleString('en', {minimumFractionDigits: 2}); 
   },
     comma_four(data){    
          return Number(parseFloat(data).toFixed(4)).toLocaleString('en', {minimumFractionDigits: 4}); 
   },
  round(num, value) {
    let dta = num * value;
    var m = Number((Math.abs(dta) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(dta);
  },
  //  hijri(data) {  
  //    let dateTime = new Date(data);
  //   let dayOfYear = Math.floor((dateTime - new Date(dateTime.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24))
  //   let hijriDate = ((dateTime.getFullYear() - 621.5643) * 365.24225 + dayOfYear-1) / 354.36707
  //   let hijriYear = Math.floor(hijriDate)
  //   let hijriMonth = Math.ceil((hijriDate - Math.floor(hijriDate)) * 354.36707 / 29.530589)
  //   let hijriDay = Math.floor((hijriDate - Math.floor(hijriDate)) * 354.36707 % 29.530589)
  //   if (hijriDay === 0) {
  //       hijriMonth -= 1; // Subtract 1 from the month
  //       hijriDay = 30; // Set the day to the last day of the previous month
  //     }
  //   return hijriYear +'/'+ hijriMonth +'/'+ hijriDay;

  //  },
  hijri(data) {
      let dateTime = new Date(data);
      let dayOfYear = Math.floor((dateTime - new Date(dateTime.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24))
      let hijriDate = ((dateTime.getFullYear() - 621.5643) * 365.24225 + dayOfYear - 1) / 354.36707
      let hijriYear = Math.floor(hijriDate)
      let hijriMonth = Math.ceil((hijriDate - Math.floor(hijriDate)) * 354.36707 / 29.530589)
      let hijriDay = Math.floor((hijriDate - Math.floor(hijriDate)) * 354.36707 % 29.530589)
      if (hijriDay === 0) {
        hijriMonth -= 1;
        hijriDay = 29;
      } else if (hijriDay === 1) {
        hijriMonth -= 1;
        hijriDay = 30;
      }
      return hijriYear + '/' + hijriMonth + '/' + hijriDay;

    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];
          this.invoice_total_amount = this.invoice.total_amount;
          this.invoice_vat_amount =  this.invoice.vat_amount;
          this.invoice_gross_amount = this.invoice.gross_amount;
          this.invoice_rentention_amount = this.invoice.retention_amount;
          this.invoice_net_amount = this.invoice.net_amount
          
        if (this.invoice.invoice_type == "Progress Service Order") {
            this.hc_address = "الدفــعـات";
          } else if (this.invoice.invoice_type == "Final Payment") {
            this.hc_address = "الاستــقـطاع";
          } else if (this.invoice.invoice_type == "Other") {
            this.hc_address = "اخــري";
          } else {
            this.hc_address = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    increment_index(key) {
      return Number(key) + 1;
    },
  },
};
</script>
<style scoped>
span {
  font-size: 15px;
}
.border-bottom {
  border-bottom: 2px solid black !important;
}
.line-height {
  line-height: 10px;
}
.br {
  border: black solid 2px;
}
.table {
  border: 2px solid black;
}
.table tr td {
  border:2px solid black !important; 
  /* border: none !important; */
  /* margin: 4px; */
  font-size: 18px !important;
}
.table tr th {
  border: 2px solid black;
  font-size: 18px !important;
}

.headertable tr td{
  border: none !important;
  font-weight: bold;
}
</style>

